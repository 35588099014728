import React from "react"
import { graphql } from "gatsby"

import Dataviz from "../components/DataViz"
import SEO from "../components/Seo"
const artwork = () => {
  return (
    <>
      <SEO title="Kentaro Takahira" />
      <main>
        <section className="projects-page">
          <Dataviz title="Dataviz" />
        </section>
      </main>
    </>
  )
}

export default artwork
